import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

const Page = ({ data }) => (
  <Layout
    title={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.title}
    description={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.description}
  >
    <div className="common-page">
      <Subheader title="NEWSLETTER" />

      <div className="container">
        <iframe
          width="100%"
          height="550"
          src="https://sibforms.com/serve/MUIEAO9veZy__0zDpsCTVFCv4rZqlDLUPyxfpnEYE_BcdnSgkFqVn5n6kQE7BPehYxd1g9Pvmh_YkZXYGT1JXfYorzc-DxznFnkHo9HPuseMDGTXNzohWw8zRelq-MrPbuI9GD8oyb5_T3OC1d37Er5dkb7-VNR7FchKtDSLaAJn4_kQ5sz88Qyws29IHCO2nC3Gkis9u2HmoAa3"
          frameBorder="0"
          scrolling="auto"
          allowFullScreen
        />
      </div>
    </div>
  </Layout>
);

export default Page;

export const query = graphql`
  query NewsletterQuery {
    datoCmsFixtext(name: { eq: "Newsletter" }) {
      fixtext
      introtext
      seo {
        title
        description
      }
    }
  }
`;
